export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_FAVORITE_SUCCESS = "GET_FAVORITE_SUCCESS";
export const GET_FAVORITE_FAIL = "GET_FAVORITE_FAIL";
export const ADD_FAVORITE_SUCCESS = "ADD_FAVORITE_SUCCESS";
export const ADD_FAVORITE_FAIL = "ADD_FAVORITE_FAIL";
export const DELETE_FAVORITE_SUCCESS = "DELETE_FAVORITE_SUCCESS";
export const DELETE_FAVORITE_FAIL = "DELETE_FAVORITE_FAIL";
